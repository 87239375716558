
import { ResponsiveBullet } from '@nivo/bullet';

// @ts-ignore
import { Defs } from '@nivo/core';

import { Col, Row, Tag } from "antd";

import React, { useEffect, useState } from "react";
import AnimatedNumber from 'react-animated-number';
import { Sparklines, SparklinesLine } from 'react-sparklines';
import { memo } from 'react-tracked';
import { Sensor } from "../../service";
import { useTracked } from "../../state";
import SparklinesSpot from "../General/SparklinesSpot";

// @ts-ignore
import styles from "./bulletwidget.module.scss";

interface ITrendLine {
    sensor: Sensor;
}

function TrendLine(props: ITrendLine) {

    const [state, dispatch] = useTracked();
    const [data, setData] = useState([]);

    useEffect(() => {
        const i = state.hydropowerrealtimeplantlatest.findIndex((item) => item.id === state.hydropowerplant.id);
        if (i !== -1) {
            const sensor = state.hydropowerrealtimeplantlatest[i].sensors.find(((sensorItem) => sensorItem.name === props.sensor.name));
            if (data.length >= 20) {
                data.shift();
            }
            setData([...data, sensor.value]);
        }
    }, [state.hydropowerrealtimeplantdatalatest]);

    return (
        <>
            <Sparklines data={data} limit={20} margin={12}>
                <SparklinesLine style={{ fill: "none", strokeWidth: 4 }} color="#4BB2E7" />
                <SparklinesSpot style={{ stroke: "#4BB2E7", strokeWidth: 6, fill: "white" }} size={10} />
            </Sparklines>
        </>
    );
}

interface ISensorValue {
    sensor: Sensor;
}

function SensorValue(props: ISensorValue) {

    const [state, dispatch] = useTracked();
    const [sensor, setSensor] = useState<Sensor>({ value: 0 });

    useEffect(() => {
        const i = state.hydropowerrealtimeplantlatest.findIndex((item) => item.id === state.hydropowerplant.id);
        if (i !== -1) {
            const _sensor = state.hydropowerrealtimeplantlatest[i].sensors.find(((sensorItem) => sensorItem.name === props.sensor.name));
            setSensor(_sensor);
        }

    }, [state.hydropowerrealtimeplantlatest]);

    const warning = () => {
        const high = parseInt(props.sensor.props.find((a) => a.name === "HighLevelAlert").value, 10);
        const low = parseInt(props.sensor.props.find((a) => a.name === "LowLevelAlert").value, 10);

        if (sensor.value >= high) { return true; }
        if (sensor.value <= low) { return true; }
        return false;
    };

    return (
        <>
            {!sensor ?
                <Tag style={{ marginTop: 0, marginRight: 0, width: "100%", textAlign: "center" }} color="#E74B5E">Offline</Tag> :
                <>
                    <Tag style={{ marginTop: 0, marginRight: 0, width: "100%", textAlign: "center" }} color={warning() ? "#E74B5E" : null}><AnimatedNumber component="span" stepPrecision={1} value={sensor.value}
                        style={{
                            transition: '0.8s ease-out',
                            fontSize: 14,
                            fontWeight: 900,
                            transitionProperty:
                                'background-color, color, opacity'
                        }}
                        formatValue={(n) => n.toFixed(1)}
                        duration={900}
                    /><span style={{ fontWeight: 400, marginLeft: 6 }}>{props.sensor.props.find((a) => a.name === "Unit").value}</span>
                    </Tag>
                </>
            }
        </>
    );
};

function CustomRange({ x, y, width, height, color, }) {
    return (
        <><Defs
            defs={[
                {
                    id: "pattern-" + height,
                    type: 'patternLines',
                    background: 'transparent',
                    color,
                    lineWidth: 1,
                    spacing: 6,
                    rotation: -45,
                },
            ]}
        />

            <line
                x1={x - 6}
                x2={width + 7}
                y1={y}
                y2={y}
                stroke={color}
                strokeWidth={2}
                strokeDasharray="2,3"
                fill="none"
                rotate={180}
            />
        </>);
}

/*
            <rect
                x={x + 2}
                y={y}
                width={width - 4}
                height={height ? height - 2 : height}
                fill={"url(#pattern-" + height + ")"}
                fillOpacity={0.7}
                strokeWidth={0}
            />
*/

function CustomMarker({ width, height, y, x, size, color, onMouseEnter, onMouseMove, onMouseLeave }) {
    return (
        <line
            x1={x - 10}
            x2={x + 11}
            y1={y}
            y2={y}
            width={width}
            stroke={color}
            strokeWidth={2}
            strokeDasharray="1,2"
            fill="none"
            rotate={180}
        />
    );
}

function CustomMeasure({ x, y, width, height, color, onMouseEnter, onMouseMove, onMouseLeave }) {
    return (
        <>
            <line
                x1={x}
                x2={width}
                y1={y}
                y2={y}
                stroke={"#4BB2E7"}
                strokeWidth={2}
                fill="none"
                rotate={180}
            />
            <rect
                x={x}
                y={y + 2}
                //rx={height / 2}
                //ry={height / 2}
                width={width}
                height={height}
                fill={color}

                onMouseEnter={onMouseEnter}
                onMouseMove={onMouseMove}
                onMouseLeave={onMouseLeave}
            />
        </>
    );
}

interface IBullet {
    sensor: Sensor;
}

const Bullet = memo((props: IBullet) => {

    const [state, dispatch] = useTracked();
    //const [sensor, setSensor] = useState<Sensor>({ value: 0 });

    const [productionData, setProductionData] = useState(
        {
            "id": "production",
            "ranges": [
                40,
                0,
                100
            ],
            "measures": [
                0
            ],
            "markers": [
                0,
            ]
        }
    );

    const latest = state.hydropowerrealtimeplantlatest[state.hydropowerrealtimeplantlatest.findIndex((item) => item.id === state.hydropowerplant.id)];
    const sensor = latest ? latest.sensors.find(((sensorItem) => sensorItem.name === props.sensor.name)) : { value: 0 };

    useEffect(() => {
        if (sensor) {
            const high = parseInt(props.sensor.props.find((a) => a.name === "HighLevelAlert").value, 10);
            const low = parseInt(props.sensor.props.find((a) => a.name === "LowLevelAlert").value, 10);
            setProductionData({ ...productionData, markers: [high, low], measures: [sensor.value] });
        }
    }, [sensor.value]);


    useEffect(() => {
        if (props) {
            if (props.sensor && props.sensor.props) {
                const type = props.sensor.props.find((a) => a.name === "BulletType");
                const max = parseInt(props.sensor.props.find((a) => a.name === "Max").value, 10);
                const high = parseInt(props.sensor.props.find((a) => a.name === "HighLevelAlert").value, 10);
                const low = parseInt(props.sensor.props.find((a) => a.name === "LowLevelAlert").value, 10);
                setProductionData({
                    ...productionData, ranges: [max, 0], markers: [high, low], measures: [sensor.value]
                });
            }
        }
    }, []);

    return (
        <><span style={{ visibility: "hidden", display: "none" }}>{sensor.value}</span>
            <ResponsiveBullet
                layout="vertical"
                height={200}
                data={[productionData]}
                margin={{ top: 24, right: 34, bottom: 6, left: 24 }}
                spacing={0}
                markerColors={["#E74B5E", "#E74B5E"]}
                measureColors="#1C384C"
                titleAlign="start"
                titleOffsetX={-40}
                titleOffsetY={200}
                titleRotation={-90}
                markerSize={1}
                measureSize={1}
                rangeSize={1}
                markerComponent={CustomMarker}
                animate={true}
                motionStiffness={90}
                motionDamping={12}
                measureComponent={CustomMeasure}
                rangeColors={"#191D22"}
                //rangeComponent={CustomRange}
                theme={{
                    textColor: '#BDBDBD',
                    axis: {
                        ticks: {
                            line: {
                                stroke: '#FFF',
                                strokeWidth: 1
                            },
                            text: {
                                fontSize: 11
                            }
                        },
                    }
                }}
            /></>
    );
});

export enum IBulletWidgetType {
    realtimePlantData = 1,
    realtimeCustomerData = 2,
    realtimePlant = 3,
}

export interface IBulletWidget {
    sensor: Sensor;
}

function BulletWidget(props: IBulletWidget) {

    return (
        <Row style={{ width: 80 }}>
            <Col xs={24}>
                <div style={{ height: 200 }}>
                    <Bullet sensor={props.sensor} />
                </div>
            </Col>
            <Col xs={24}>
                <div style={{ marginTop: -10 }}>
                    <SensorValue sensor={props.sensor} />
                    <div style={{ height: 30, paddingTop: 8, paddingLeft: 8, paddingRight: 8 }}>
                        <TrendLine sensor={props.sensor} />
                    </div>
                    <div style={{ fontSize: 10, paddingTop: 0, textTransform: "uppercase", textAlign: "center" }}>{props.sensor ? props.sensor.name : "N/A"}</div>
                </div>
            </Col>
        </Row>
    );
}

export default BulletWidget;
