import React, { useEffect, useState } from "react";

import { LeftOutlined, MenuOutlined, DotChartOutlined, DashboardOutlined, PieChartOutlined } from "@ant-design/icons";
import { Button, Card, Col, Row, Select, Menu, Dropdown, InputNumber, Form, Divider, Tag } from "antd";

import AnimatedNumber from 'react-animated-number';
import { ResponsiveScatterPlot } from '@nivo/scatterplot';

import { navigate } from '@reach/router';

import { useDispatch, useTrackedState } from '../../state';

import EventsCalendarWidget from '../EventsCalendarWidget';
import PlantMenu from "../General/PlantMenu";
import moment from "moment";
import BigDivider from "../General/Divider";

// @ts-ignore
import ComingSoon from '../../../static/cominghealth.jpg';


interface HydropowerPlantEconomicsState {
    kraftpris: number;
    normalproduksjon: number;
    installerteffekt: number;
    brukstid: number;
}

const { Option } = Select;

// @ts-ignore
//import styles from "./plantoverview.module.scss";

export interface IHydropowerPlantEconomics {
    path?: string;
}

function HydropowerPlantEconomics(props: IHydropowerPlantEconomics) {

    const dispatch = useDispatch();
    const state = useTrackedState();

    const [todaysProductionTotal, setTodaysProductionTotal] = useState(0);
    const [todaysProductionHour, setTodaysProductionHour] = useState(0);
    const [producedStartOfDay, setProducedStartOfDay] = useState(0);

    const [localState, setLocalState] = useState<HydropowerPlantEconomicsState>({ kraftpris: 0.3, installerteffekt: 800, normalproduksjon: 2, brukstid: 2500 });

    const latest = state.hydropowerrealtimeplantlatest[state.hydropowerrealtimeplantlatest.findIndex((item) => item.id === state.hydropowerplant.id)];
    const sensor = latest ? latest.sensors.find(((sensorItem) => sensorItem.name === "Effekt")) : { value: 0 };

    useEffect(() => {
        if (state.hydropowerplantsensordata.length > 0) {
            if (state.hydropowerplantsensordata[0].name === "Effekt") {
                setProducedStartOfDay(state.hydropowerplantsensordata[0].value);
            }
        }
        if (sensor !== undefined) {
            if (producedStartOfDay !== 0) {
                const produced = sensor.value - producedStartOfDay
                console.log("produced", produced);
                // @ts-ignore
                setTodaysProductionTotal(produced);
            }
        }
    }, [sensor, state.hydropowerplantsensordata, producedStartOfDay]);

    useEffect(() => {
        dispatch({ type: 'GET_HYDROPOWERPLANTSENSORDATA', request: { sensor: "Effekt", end: moment().unix().valueOf(), start: moment().startOf('day').unix().valueOf(), groupBy: "1m" } });
    }, []);

    const onFinish = values => {
        console.log('Success:', values);
    };

    const onFinishFailed = errorInfo => {
        console.log('Failed:', errorInfo);
    };

    const onChange = (values, e) => {

        const brukstid = ((values.normalproduksjon ? values.normalproduksjon * 1000000 : localState.normalproduksjon * 1000000) / (values.installerteffekt ? values.installerteffekt : localState.installerteffekt)).toFixed(0);
        console.log(values);
        console.log({ ...localState, ...values });
        setLocalState({ ...localState, ...values, brukstid });
    };

    return (
        <Row style={{ margin: 24 }} gutter={[12, 12]}>
            <Col xs={24} style={{ padding: 0 }}>
                <h1 style={{ color: "#FFF", marginBottom: 0 }}><PieChartOutlined /> Economics</h1>
                <div style={{ color: "#FFF", marginTop: 0, opacity: 0.7, marginBottom: 24 }}>Calculations and forecasts to help optimize the running of the plant</div>
            </Col>

            <Col xs={24} md={8}>
                <Card title={"Produksjon"}>
                    <div style={{ fontSize: 12, opacity: 0.9 }}>Så langt i dag (kr)</div>
                    <AnimatedNumber component="div" stepPrecision={1} value={(todaysProductionTotal * localState.kraftpris)}
                        style={{
                            transition: '0.8s ease-out',
                            fontSize: 44,
                            transitionProperty:
                                'background-color, color, opacity'
                        }}
                        formatValue={(n) => n.toFixed(0) + ",-"}
                        duration={900}
                    />
                    <Tag>{(todaysProductionTotal * localState.kraftpris / 24).toFixed(0)},- kr/h</Tag>
                    <Tag>{todaysProductionTotal} kWh</Tag>
                </Card>
            </Col>
            <Col xs={24} md={8} >
                <Card title={"Nedtidskostnad"} extra={<Tag color="grey">Normal</Tag>}>
                    <div style={{ fontSize: 12, opacity: 0.9 }}>Per døgn (kr)</div>
                    <AnimatedNumber component="div" stepPrecision={1} value={(((localState.installerteffekt * localState.brukstid) / 8760) * localState.kraftpris * 24)}
                        style={{
                            transition: '0.8s ease-out',
                            fontSize: 44,
                            transitionProperty:
                                'background-color, color, opacity'
                        }}
                        formatValue={(n) => n.toFixed(0) + ",-"}
                        duration={900}
                    />
                    <Tag>{(((localState.installerteffekt * localState.brukstid) / 8760) * localState.kraftpris).toFixed(0)},- kr/h</Tag>
                </Card>
            </Col>
            <Col xs={24} md={8}>
                <Card title={"Nedetidskostnad"} extra={<Tag color="red">Høy</Tag>}>
                    <div style={{ fontSize: 12, opacity: 0.9 }}>Per døgn (kr)</div>
                    <AnimatedNumber component="div" stepPrecision={1} value={((localState.installerteffekt * localState.kraftpris) * 24)}
                        style={{
                            transition: '0.8s ease-out',
                            fontSize: 44,
                            transitionProperty:
                                'background-color, color, opacity'
                        }}
                        formatValue={(n) => n.toFixed(0) + ",-"}
                        duration={900}
                    />
                    <Tag>{(localState.installerteffekt * localState.kraftpris).toFixed(0)},- kr/h</Tag>
                </Card>
            </Col>
            <Divider style={{ borderColor: "#1E2125" }} />
            <Col xs={24}>
                <Row gutter={[12, 12]}>
                    <Col xs={24} md={12}>
                        <Card title={"Brukstid"}>
                            <Form
                                labelCol={{ span: 8 }}
                                wrapperCol={{ span: 16 }}
                                name="basic"
                                initialValues={{ remember: true }}
                                onValuesChange={onChange}
                                onFinish={onFinish}
                                onFinishFailed={onFinishFailed}
                                hideRequiredMark={true}
                            >
                                <Form.Item
                                    label="Kraftpris (kr/kWh)"
                                    name="kraftpris"
                                    rules={[{ required: true, message: 'Du må skrive inn en kraftpris!' }]}
                                    initialValue={0.3}
                                >
                                    <InputNumber size="large" min={0} step={0.1} max={100000} />
                                </Form.Item>
                                <Form.Item
                                    label="Normalproduksjon (GWh)"
                                    name="normalproduksjon"
                                    rules={[{ required: true, message: 'Du må skrive inn normalproduksjon!' }]}
                                    initialValue={2}
                                >
                                    <InputNumber size="large" step={0.1} min={0.1} max={100000} />
                                </Form.Item>
                                <Form.Item
                                    label="Installert effekt (kW)"
                                    name="installerteffekt"
                                    rules={[{ required: true, message: 'Du må skrive inn installert effekt!' }]}
                                    initialValue={800}
                                >
                                    <InputNumber size="large" min={1} max={100000000} />
                                </Form.Item>
                                <Divider />
                                <Form.Item
                                    label="Brukstid"
                                >
                                    {localState.brukstid} timer per år
                                </Form.Item>
                            </Form>
                        </Card>
                    </Col>
                    <Col xs={24} md={12}>
                        <Row gutter={[12, 12]}>

                        </Row>
                    </Col>
                </Row>
            </Col>
            <Divider style={{ borderColor: "#1E2125" }} />
            <Col xs={24}>
                <img width="1024"  src={ComingSoon} />
            </Col>
        </Row>
    );
}

export default HydropowerPlantEconomics;
