
import { ScenegraphLayer } from '@deck.gl/mesh-layers';
import DeckGL from '@deck.gl/react';
import React, { ReactElement, useEffect, useState } from "react";
import ReactMapGL, { _MapContext as MapContext, Popup } from 'react-map-gl';
import { useTrackedState } from "../../state";

import { COORDINATE_SYSTEM } from '@deck.gl/core';

import { GLTFLoader } from '@loaders.gl/gltf';
import { load } from '@loaders.gl/core';

// @ts-ignore
import styles from "./statewidget.module.scss";
import { Sensor } from "../../service";
import SensorMarker from "../SensorMarker";
import { Switch, Row, Col, Progress } from "antd";

export interface IMapWidget {
}

function MapWidget(props: IMapWidget) {

    const TOKEN = process.env.GATSBY_MAPBOX_TOKEN;
    const state = useTrackedState();

    const [sensorMakers, setSensorMarkers] = useState<ReactElement[]>([]);
    const [layers, setLayers] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [loadingStatus, setLoadingStatus] = useState("normal");

    const initialViewState = {
        longitude: state.hydropowerplant.properties.coordinates.longitude,
        latitude: state.hydropowerplant.properties.coordinates.latitude,
        zoom: 16.6,
        pitch: 60,
        bearing: 0
    };

    useEffect(() => {
        const _d = state.hydropowerplant.sensors.map((sensor: Sensor) => {
            const showInMap = sensor.props.find((p) => p.name === "showInMap");
            if (showInMap) {
                const latitude = parseFloat(sensor.props.find((p) => p.name === "latitude").value);
                const longitude = parseFloat(sensor.props.find((p) => p.name === "longitude").value);
                return (<Popup
                    key={sensor.name}
                    latitude={latitude}
                    longitude={longitude}
                    closeButton={false}
                    closeOnClick={false}
                    captureClick={true}
                    onClose={() => { }}
                    dynamicPosition={false}
                    anchor="bottom">
                    <SensorMarker sensor={sensor} />
                </Popup>);
            }
        });
        setSensorMarkers(_d);
    }, [state.hydropowerplant]);

    useEffect(() => {
        //loadObjects();
    }, []);

    async function onChange(checked) {
        if (checked) {
            setLoadingStatus("active");
            setIsLoading(true);
            await loadObjects();
            setLoadingStatus("success");
            setIsLoading(false);
        }
    }

    const loadObjects = async () => {
        const glb = await load("/model.glb", GLTFLoader);
        setLayers([

            new ScenegraphLayer({
                id: 'scenegraph-layer',
                data: [{ position: [0, 0] }],
                coordinateSystem: COORDINATE_SYSTEM.METER_OFFSETS,
                //coordinateOrigin: [9.890505, 59.841838 , 47], // 59.840949, 9.891021 // 59.841398, 9.890921 // 59.841849, 9.890595 // 59.841838, 9.890505
                coordinateOrigin: [9.890505, 59.841838, 47], // 59.840949, 9.891021 // 59.841398, 9.890921 // 59.841849, 9.890595 // 59.841838, 9.890505
                scenegraph: glb
            })
        ]);
    };

    return (
        <div style={{ height: 600 }}>
            <DeckGL
            style={{overflow: "hidden"}}
                ContextProvider={MapContext.Provider}
                initialViewState={initialViewState}
                controller={true}
                layers={layers}
                width="100%"
                height={600}
            >
                <ReactMapGL
                    width="100%"
                    height="100%"
                    mapStyle="mapbox://styles/bloever/ckah2my8e0r7j1imus961o353"
                    mapboxApiAccessToken={TOKEN}
                >
                </ReactMapGL>
                {sensorMakers}
            </DeckGL>

            <Row justify="end" style={{ padding: 12 }}>
                <Col xs={20}>{isLoading ? <Progress percent={30} style={{ paddingRight: 12, color: "#FFF" }} status={loadingStatus} /> : <></>}</Col>
                <Col><Switch style={{ marginRight: 12 }} checkedChildren="3D PÅ" unCheckedChildren="3D AV" defaultChecked={false} onChange={onChange} /></Col>
            </Row>
        </div>
    );
}

export default MapWidget;
