import React, { useEffect } from 'react';

import { LogoutOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Popover } from 'antd';

import HydropowerPlantOverview from '../components/HydropowerPlantOverview';
import { ActionTypes, useTracked } from '../state';

import 'mapbox-gl/dist/mapbox-gl.css';

// Styles
// @ts-ignore
import styles from './dashboard.module.scss';
import './defaults.css';
import { Router } from '@reach/router';
import HydropowerPlantAnalytics from '../components/HydropowerPlantAnalytics';
import PlantMenu from '../components/General/PlantMenu';
import HydropowerPlantEconomics from '../components/HydropowerPlantEconomics';



function Plant({ children, location }) {
    const TOKEN = "pk.eyJ1IjoiYmxvZXZlciIsImEiOiJja2FncWFjMW4wOTBrMnJuN2MwbXcxNGcyIn0.BBrECWNyVwehEC5kKnOQIg";

    const [state, dispatch] = useTracked();

    useEffect(() => {
        loadData();
    }, []);

    const loadData = async () => {
        dispatch({ type: 'GET_HYDROPOWERPLANTS' });
        dispatch({ type: 'GET_CUSTOMER' });
    };

    useEffect(() => {
        const plantName = location.pathname.split("/")[2];
        const plant = state.hydropowerplants.find((item) => item.name === plantName);
        if (plant) {
            //const target_copy = Object.assign({}, plant);
            dispatch({ type: ActionTypes.SET_DASHBOARD, data: { plantDetailsActive: true }, hydropowerPlant: plant });
        }

    }, [state.hydropowerplants]);

    const renderAccount = () => {
        const content = (
            <div>
                <Button
                    type="link"
                    icon={<LogoutOutlined />}
                    onClick={() => { }}
                >
                    Logg ut
                </Button>
            </div>
        );

        return (
            <Popover placement="topLeft" content={content} trigger="click">
                <Button
                    style={{ position: "fixed", zIndex: 999, marginTop: 24, right: 24, top: 0 }}
                    type="default"
                    shape="circle-outline"
                    icon={<UserOutlined />}
                    size="large" />
            </Popover>
        );
    };

    return (
        <div>
            <PlantMenu />
            {renderAccount()}
            <Router>
                <HydropowerPlantOverview path="/plant/:plantName" />
                <HydropowerPlantAnalytics path="/plant/:plantName/analytics" />
                <HydropowerPlantEconomics path="/plant/:plantName/economics" />
            </Router>
        </div>
    );
}

export default Plant;
