
import React, { useEffect, useState } from "react";

import { ResponsiveBar } from "@nivo/bar";
import moment from "moment";
import { Sensor } from "../../service";
import { useTracked } from "../../state";

// @ts-ignore
// import styles from "./styles.module.scss";

export enum IBarWidgetType {
    sensorDifference = 1,
}

export interface IBarWidget {
    sensor: Sensor;
    type: IBarWidgetType;
}
function BarWidget(props: IBarWidget) {

    const [state, dispatch] = useTracked();
    const [chartData, setChartData] = useState([]);

    useEffect(() => {
        if (props.sensor) {
            if (props.type === IBarWidgetType.sensorDifference) {
                dispatch({ type: 'GET_HYDROPOWERPLANTSENSORDIFFERENCE', request: { sensor: props.sensor.name, end: moment().unix().valueOf(), start: moment().add(-14, 'days').unix().valueOf(), groupBy: "1d" } });
            }
        }
    }, [props]);

    useEffect(() => {
        if (props.type === IBarWidgetType.sensorDifference) {
            const _chartData = state.hydropowerplantsensordifference.map((data) => ({ time: moment(data.timestamp).format("DD/MM"), [props.sensor.name]: data.value ? data.value : null }));
            setChartData(_chartData);
        }
    }, [state.hydropowerplantsensordifference]);

    const CustomBarComponent = ({ x, y, width, height, color, label }) => {
        return (
            <>

                <line
                    x1={x}
                    x2={x + width}
                    y1={y + 1}
                    y2={y + 1}
                    stroke={"#4BB2E7"}
                    strokeWidth={2}
                    fill="none"
                    rotate={180}
                />
                <rect
                    x={x}
                    y={y + 2}
                    //rx={height / 2}
                    //ry={height / 2}
                    width={width}
                    height={height}
                    fill={color}
                />
                <svg x={x} y={y} width={width} >
                    <text x="50%" y={18} dominantBaseline="middle" textAnchor="middle" fill="#FFF" fontSize={10} >{label}</text>
                </svg>
            </>);
    };

    //  <circle cx={x + width / 2} cy={y + height / 2} r={Math.min(width, height) / 2} fill={color} />

    const MyResponsiveBar = () => (
        <ResponsiveBar
            barComponent={CustomBarComponent}
            data={chartData}
            keys={[props.sensor.name]}
            indexBy="time"
            margin={{ top: 6, right: 12, bottom: 24, left:52 }}
            padding={0.3}
            colors="#213845"
            xScale={{ type: 'time' }}
            /*defs={[
                {
                    id: 'dots',
                    type: 'patternDots',
                    background: 'inherit',
                    color: '#38bcb2',
                    size: 4,
                    padding: 1,
                    stagger: true
                },
                {
                    id: 'lines',
                    type: 'patternLines',
                    background: 'inherit',
                    color: '#eed312',
                    rotation: -45,
                    lineWidth: 6,
                    spacing: 10
                }
            ]}*/
            /*fill={[
                {
                    match: {
                        id: 'fries'
                    },
                    id: 'dots'
                },
                {
                    match: {
                        id: 'sandwich'
                    },
                    id: 'lines'
                }
            ]}*/
            borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
            axisTop={null}
            axisRight={null}
            axisBottom={{ format: d => d.toString() }}



            /*axisLeft={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: 'food',
                legendPosition: 'middle',
                legendOffset: -40
            }}*/
            enableGridY={false}
            labelSkipWidth={12}
            labelSkipHeight={12}
            //labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
            legends={[
                {
                    dataFrom: 'keys',
                    anchor: 'bottom-right',
                    direction: 'column',
                    justify: false,
                    translateX: 120,
                    translateY: 0,
                    itemsSpacing: 2,
                    itemWidth: 100,
                    itemHeight: 20,
                    itemDirection: 'left-to-right',
                    itemOpacity: 0.85,
                    symbolSize: 20,
                    effects: [
                        {
                            on: 'hover',
                            style: {
                                itemOpacity: 1
                            }
                        }
                    ]
                }
            ]}
            theme={{
                // @ts-ignore
                textColor: '#FFF',
                axis: {
                    ticks: {
                        line: {
                            stroke: '#FFF',
                            strokeWidth: 1
                        },
                        text: {
                            fontSize: 11
                        }
                    },
                }
            }}
            animate={true}
            motionStiffness={90}
            motionDamping={15}
        />)
        ;

    return (
        <div style={{ height: "auto" }}>
            <div style={{ marginTop: 10, height: 200 }}>
                {MyResponsiveBar()}
            </div>
        </div>
    );
}

export default BarWidget;
