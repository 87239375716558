import React, { useEffect, useState } from "react";
import { Progress, Col, Row, Menu, Button, Dropdown, Popover, Divider } from "antd";
import { useTracked } from "../../state";
import { DashboardOutlined, DotChartOutlined, LeftOutlined, MenuOutlined, PieChartOutlined } from "@ant-design/icons";
import { navigate } from "@reach/router";

function PlantMenu() {

    const [state, dispatch] = useTracked();
    const [isMenuVisible, setIsMenuVisible] = useState(false);

    const menu = () => {
        return (
            <Row style={{ width: 150 }}>
                <Col xs={24}>
                    <Button size="middle" style={{ marginTop: 6 }} type="link" icon={<DashboardOutlined />} onClick={() => { setIsMenuVisible(false); navigate("/plant/" + state.hydropowerplant.name); }}>Operations</Button>
                </Col >
                <Button size="middle" type="link" icon={<DotChartOutlined />} onClick={() => { setIsMenuVisible(false); navigate("/plant/" + state.hydropowerplant.name + "/analytics"); }}>Analytics</Button>
                <Col xs={24}>
                    <Button size="middle" type="link" icon={<PieChartOutlined />} onClick={() => { setIsMenuVisible(false); navigate("/plant/" + state.hydropowerplant.name + "/economics"); }}>Economics</Button>
                </Col>
                <Divider style={{ marginTop: 6, marginBottom: 6 }} />
                <Col xs={24}>
                    <Button size="middle" type="link" icon={<LeftOutlined />} onClick={() => {
                        navigate("/dashboard");
                    }}>Back</Button>
                </Col>
            </Row>
        );
    };

    return (
        <Row style={{ borderBottomWidth: 1, borderBottomStyle: "solid", borderBottomColor: "#1E2125", backgroundColor: "#16191D" }}>
            <Col xs={6} lg={9}>
                <Row style={{ height: 80 }}>
                    <Col xs={24} xl={0}>
                        <Popover placement="topLeft" content={menu} trigger="click" visible={isMenuVisible} onVisibleChange={(e) => { setIsMenuVisible(e); }}>
                            <Button size="large" style={{ marginLeft: 24, marginTop: 24 }} type="default" shape="circle" onClick={() => { setIsMenuVisible(!isMenuVisible); }} icon={<MenuOutlined />} />
                        </Popover>
                    </Col>
                    <Col xs={0} xl={24} style={{minWidth: 480}}>
                        <Button size="large" style={{ marginLeft: 24, marginTop: 24 }} type="default" shape="circle" icon={<LeftOutlined />} onClick={() => { navigate("/dashboard"); }} />
                        <Button size="middle" style={{ color: "#FFF", marginLeft: 24, marginTop: 24 }} type="ghost" icon={<DashboardOutlined />} onClick={() => { navigate("/plant/" + state.hydropowerplant.name); }}>Operations</Button>
                        <Button size="middle" style={{ color: "#FFF", marginLeft: 12, marginTop: 24 }} type="ghost" icon={<DotChartOutlined />} onClick={() => { navigate("/plant/" + state.hydropowerplant.name + "/analytics"); }}>Analytics</Button>
                        <Button size="middle" style={{ color: "#FFF", marginLeft: 12, marginTop: 24 }} type="ghost" icon={<PieChartOutlined />} onClick={() => { setIsMenuVisible(false); navigate("/plant/" + state.hydropowerplant.name + "/economics"); }}>Economics</Button>
                    </Col>
                </Row>
            </Col>
            <Col xs={12} lg={6}>
                <div style={{ marginTop: 12, fontSize: 24, color: "#FFF", textAlign: "center", }}>{state.hydropowerplant.name}</div>
                <div style={{ marginTop: 0, fontSize: 10, color: "#A7A7A7", fontWeight: 700, textAlign: "center", textTransform: "uppercase" }}>{state.hydropowerplant.description}</div>
            </Col>
            <Col xs={6} lg={9}></Col>
        </Row>
    );
}

export default PlantMenu;
