import React, { useEffect, useState } from "react";
import AnimatedNumber from 'react-animated-number';
import { HydropowerPlant } from "../../service";
import { KinetikRealtimePlantDataMessage } from "../../service";

import { LeftOutlined, DollarOutlined, MenuOutlined, DotChartOutlined, DashboardOutlined } from "@ant-design/icons";
import { Button, Card, Col, Row, Select, Tag, Menu, Dropdown } from "antd";
import { Circle } from 'rc-progress';

import { navigate } from '@reach/router';
import { KinetikRealtimePlantMessage } from '../../service/models/KinetikRealtimePlantMessage';
import { IMapViewState, useDispatch, useTrackedState } from '../../state';
import BarWidget, { IBarWidgetType } from '../BarWidget';
import BulletWidget from '../BulletWidget';
import EventsCalendarWidget from '../EventsCalendarWidget';
import BigDivider from '../General/Divider';
import LineWidget, { ILineWidgetType } from '../LineWidget';
import MapWidget from '../MapWidget';
import StateWidget from '../StateWidget';
import VoltageWidget, { IVoltageMeasureItem } from '../VoltageWidget';

const { Option } = Select;

// @ts-ignore
import styles from "./plantoverview.module.scss";
import PlantMenu from "../General/PlantMenu";


interface IPlantProductionGauge {
    hydropowerPlant: HydropowerPlant;
}

function PlantProductionGauge() {

    const state = useTrackedState();
    const dispatch = useDispatch();
    const [production, setProduction] = useState<KinetikRealtimePlantDataMessage>({ kinetikData: { production: 0 } });

    const _latest = state.hydropowerrealtimeplantdata[state.hydropowerrealtimeplantdata.findIndex((item) => item.id === state.hydropowerplant.id)];

    useEffect(() => {
        if (_latest !== null) {
            setProduction(_latest);
        }
    }, [_latest]);

    return (
        <>
            <div style={{ width: 250, height: 200 }}>
                <Circle style={{ width: 250, height: 230 }}
                    percent={[production ? (production.kinetikData.production / 1000 * 100) : 0, 1]}
                    gapDegree={90}
                    gapPosition="bottom"
                    strokeWidth={6}
                    strokeLinecap="butt"
                    strokeColor={["#1C384C", "#4BB2E7"]}
                />
                <div style={{ marginTop: -110, marginLeft: 58, }}>
                    <Tag style={{ width: 130, fontSize: 40, padding: 12, textAlign: "center" }}>
                        <AnimatedNumber component="span" stepPrecision={1} value={production ? production.kinetikData.production : 0}
                            style={{
                                transition: '0.8s ease-out',
                                fontSize: 40,
                                fontWeight: 900,
                                transitionProperty:
                                    'background-color, color, opacity'
                            }}
                            formatValue={(n) => n.toFixed(1)}
                            duration={800}
                        />
                    </Tag></div>
            </div>
            <div style={{ textAlign: "center" }}>PRODUKSJON (KW)</div>
        </>
    );
}

export interface IHydropowerPlantOverview {
    path?: string;
}

function HydropowerPlantOverview(props: IHydropowerPlantOverview) {

    const dispatch = useDispatch();
    const state = useTrackedState();
    const [hydropowerTealtimePlantLast, setHydropowerTealtimePlantLast] = useState<KinetikRealtimePlantMessage>({ states: [] });

    const [view, setView] = useState<IMapViewState>({
        latitude: state.hydropowerplant.properties.coordinates.latitude,
        longitude: state.hydropowerplant.properties.coordinates.longitude,
        bearing: 0,
        pitch: 60,
        zoom: 6
    });

    const sensors = state.hydropowerplant.sensors;
    const states = state.hydropowerplant.states;
    const waterlevelsensor = state.hydropowerplant.sensors.find((a) => a.name === "Vannstand magasin");

    const renderSensors = () => {
        return sensors.map((sensor) =>
            <Col key={sensor.name} xs={6} md={4} lg={4} xl={3} xxl={2}>
                <BulletWidget sensor={sensor} />
            </Col>
        );
    };

    const renderStates = () => {
        return states.map((state) =>
            <StateWidget key={state.name} state={state} />
        );
    };

    const handleMapEvent = (event) => {
        if (!event.isDragging && !event.iPanning && !event.isRotating && !event.isZooming) {
            if (view.latitude !== undefined) {
                //setView({latitude: view.latitude, longitude: view.longitude, zoom: view.zoom, pitch: view.pitch, bearing: view.bearing});
            }
        }
    };

    //["U-L1-L2", "UBB-L1-L2"]    {
    //       name: "L1-L2",
    //
    //       }, { name: "L2-L3", sensors: ["U-L2-L3", "UBB-L2-L3"] }, { name: "L3-L1", sensors: ["U-L3-L1", "UBB-L3-L1"] }]
    const voltageSensors: IVoltageMeasureItem[] = [
        { name: "L1-L2", sensors: [{ group: "U", name: "U-L1-L2" }, { group: "UBB", name: "UBB-L1-L2" }] },
        { name: "L2-L3", sensors: [{ group: "U", name: "U-L2-L3" }, { group: "UBB", name: "UBB-L2-L3" }] },
        { name: "L3-L1", sensors: [{ group: "U", name: "U-L3-L1" }, { group: "UBB", name: "UBB-L3-L1" }] }
    ];

    return (
        <>
            <Row>
                <Col xs={24} md={24} lg={16}>
                    <div style={{ margin: "0 auto", color: "#FFF", padding: 24, }}>
                        <Row gutter={[12, 12]} align="middle" style={{ paddingTop: 24 }}>
                            <Col xs={24} md={12} xl={7} >
                                <div style={{ margin: "0 auto", width: 250, marginBottom: 48 }}>
                                    <PlantProductionGauge />
                                </div>
                            </Col>
                            <Col xs={24} md={12} xl={7}>
                                <div style={{ margin: "0 auto", width: 280, marginBottom: 48 }}>
                                    <VoltageWidget items={voltageSensors} />
                                </div>
                            </Col>
                            <Col xs={24} md={24} xl={10}>
                                <div style={{ height: 150, paddingLeft: 48 }}>
                                    {renderStates()}
                                </div>
                                <div style={{ textAlign: "center" }}>TILSTAND</div>
                            </Col>
                        </Row>
                        <Row style={{ marginBottom: 68 }}>
                            <Col xs={24} md={24} xl={24}>
                                <Row>
                                    {renderSensors()}
                                </Row>
                            </Col>
                        </Row>

                        <BigDivider name="PRODUKSJON (KW)" duration="Siste 48t" />
                        <Row style={{ marginBottom: 68, backgroundColor: "#1E2125" }}>
                            <Col xs={24}>
                                <LineWidget sensor={{}} type={ILineWidgetType.realtimeCustomerData} />
                            </Col>
                        </Row>

                        <BigDivider name="VANNSTAND (MOH)" duration="Siste 48t" />
                        <Row style={{ marginBottom: 68, backgroundColor: "#1E2125" }}>
                            <Col xs={24}>
                                <LineWidget sensor={waterlevelsensor} type={ILineWidgetType.realtimePlant} />
                            </Col>
                        </Row>

                        <BigDivider name="PRODUKSJON (KWH)" duration="Siste 14d" />
                        <Row style={{ marginBottom: 68, backgroundColor: "#1E2125" }}>
                            <Col xs={24}>
                                <BarWidget sensor={{ name: "Effekt" }} type={IBarWidgetType.sensorDifference} />
                            </Col>
                        </Row>
                    </div>
                </Col>
                <Col xs={24} md={24} lg={8} style={{ backgroundColor: "#FFF" }}>
                    <Col xs={0} md={24} lg={24} xl={24}>
                        <MapWidget />
                    </Col>
                    <Card title={"Hendelser"} bordered={false} extra={<Select defaultValue="lucy" style={{ width: 120, height: "auto" }} disabled>
                        <Option value="lucy">I år</Option>
                    </Select>} bodyStyle={{ paddingTop: 0, paddingBottom: 12, paddingLeft: 16, paddingRight: 16 }} style={{ width: "100%" }} headStyle={{ height: 40, marginBottom: 6, borderBottomWidth: 0, paddingLeft: 16, paddingRight: 16 }}>
                        <EventsCalendarWidget />
                    </Card>
                </Col>
            </Row>
        </>
    );
}

export default HydropowerPlantOverview;
