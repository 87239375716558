import React, { useEffect, useState } from "react";

import { LeftOutlined, MenuOutlined, DotChartOutlined, DashboardOutlined, PieChartOutlined } from "@ant-design/icons";
import { Button, Card, Col, Row, Select, Menu, Dropdown } from "antd";

import { ResponsiveScatterPlot } from '@nivo/scatterplot';
import { ResponsiveLine } from '@nivo/line';

import { navigate } from '@reach/router';

import { useDispatch, useTrackedState } from '../../state';

import EventsCalendarWidget from '../EventsCalendarWidget';
import PlantMenu from "../General/PlantMenu";
import moment from "moment";
import BigDivider from "../General/Divider";
import { Configuration, HydropowerPlantApi } from "../../service";
import { getConfig } from "../../state/actions";

const { Option } = Select;

// @ts-ignore
//import styles from "./plantoverview.module.scss";


export interface IHydropowerPlantAnalytics {
    path?: string;
}

function HydropowerPlantAnalytics(props: IHydropowerPlantAnalytics) {

    const dispatch = useDispatch();
    const state = useTrackedState();

    const [chartData, setChartData] = useState([]);

    const [actualData, setActualData] = useState([]);
    const [predictionData, setPredictionData] = useState([]);

    const [productionData, setProductionData] = useState([]);
    const [cosPhiData, setCosPhiData] = useState([]);
    const [tempIndoorData, setTempIndoorData] = useState([]);
    const [ledeapparatData, setLedeapparatData] = useState([]);
    const [lopehjulData, setLopehjulData] = useState([]);
    const [rpmData, setRpmData] = useState([]);
    const [pressureData, setPressureData] = useState([]);
    const [leadData, setLeadData] = useState([]);

    useEffect(() => {
        const data = state.hydropowerplantstateelapsed.map((item) => ({
            x: moment(item.timestamp).toDate(),
            y: (item.elapsed)
        }));
        setChartData(data);
    }, [state.hydropowerplantstateelapsed]);

    useEffect(() => {
        // TODO: This seems wrong
        if (state.hydropowerplant.id !== "0000000000000000000000000") {
            load();
        }
    }, [state.hydropowerplant]);

   
    useEffect(() => {
        const _predictionData = [];
        const _actualData = [];
        const _cosPhiData = [];
        const _tempIndoorData = [];
        const _lopehjulData = [];
        const _ledeapparatData = [];
        const _productionData = [];
        state.hydropowerplantprediction.forEach((item) => {
            _predictionData.push({ x: item.timestamp, y: item.predicted });
            _actualData.push({ x: item.timestamp, y: item.actual });
            _cosPhiData.push({ x: item.timestamp, y: item.inputData.find((i) => i.sensorName === "CosPhi").value });
            _tempIndoorData.push({ x: item.timestamp, y: item.inputData.find((i) => i.sensorName === "TempInne").value });
            _lopehjulData.push({ x: item.timestamp, y: item.inputData.find((i) => i.sensorName === "Lopehjul").value });
            _ledeapparatData.push({ x: item.timestamp, y: item.inputData.find((i) => i.sensorName === "Ledeapparat").value });
            _ledeapparatData.push({ x: item.timestamp, y: item.inputData.find((i) => i.sensorName === "Ledeapparat").value });
            _productionData.push({ x: item.timestamp, y: item.inputData.find((i) => i.sensorName === "Produksjon").value });
        });

        // setProductionData(_productionData);
        // setLedeapparatData(_ledeapparatData);
        // setLopehjulData(_lopehjulData);
        // setTempIndoorData(_tempIndoorData);
        // setCosPhiData(_cosPhiData);
        setPredictionData(_predictionData);
        setActualData(_actualData);

    }, [state.hydropowerplantprediction]);

    const load = async () => {
        dispatch({
            type: 'GET_HYDROPOWERPLANTSTATEELAPSED', request: {
                hydropowerPlantId: state.hydropowerplant.id, activeStart: "True",
                activeStop: "False",
                end: moment().unix().valueOf(),
                start: moment().startOf('year').unix().valueOf(),
                groupBy: "",
                stateStart: "Startkommando",
                stateStop: "Aggrspenning>600V"
            }
        });

        dispatch({
            type: 'GET_HYDROPOWERPLANTSENSORPREDICTION', request: {
                hydropowerPlantId: state.hydropowerplant.id,
                end: moment().unix().valueOf(),
                start: moment().subtract('7', 'days').unix().valueOf(),
                groupBy: "30m",
                sensor: "Styrelager"
            }
        });

        const hydropowerplantapi: HydropowerPlantApi = new HydropowerPlantApi(new Configuration(getConfig(true)));
        const rpmSourceData = await hydropowerplantapi.v1HydropowerPlantSensorSensorDataGet({
            sensor: "Turtall",
            end: moment().unix().valueOf(),
            start: moment().add(-6, 'hours').unix().valueOf(),
            groupBy: "10m"
        });

        const data1 = rpmSourceData.data.map((item) => (
            {
                x: moment(item.timestamp).toDate(),
                y: item.value ? item.value : 0,
            }
        ));
        setRpmData(data1);

        const ledeSourceData = await hydropowerplantapi.v1HydropowerPlantSensorSensorDataGet({
            sensor: "Ledeapparat posisjon",
            end: moment().unix().valueOf(),
            start: moment().add(-6, 'hours').unix().valueOf(),
            groupBy: "10m"
        });

        const data3 = ledeSourceData.data.map((item) => (
            {
                x: moment(item.timestamp).toDate(),
                y: item.value ? item.value : 0,
            }
        ));
        setLeadData(data3);

        const trykkSourceData = await hydropowerplantapi.v1HydropowerPlantSensorSensorDataGet({
            sensor: "Trykk i innløpsrør",
            end: moment().unix().valueOf(),
            start: moment().add(-6, 'hours').unix().valueOf(),
            groupBy: "10m"
        });

        const data2 = trykkSourceData.data.map((item) => (
            {
                x: moment(item.timestamp).toDate(),
                y: item.value ? item.value : 0,
            }
        ));
        setPressureData(data2);
    };

    const MyResponsiveScatterPlot = () => (
        <ResponsiveScatterPlot
            data={[{ "id": "chart", data: chartData }]}
            margin={{ top: 60, right: 32, bottom: 70, left: 70 }}
            yScale={{ type: 'linear', min: 0, max: Math.max(...chartData.map((item) => item.y)) * 2 }}
            yFormat={function (e) { return e.toFixed(); }}
            blendMode="normal"
            axisTop={null}
            axisRight={null}
            tooltip={({ node }) => (
                <div
                    style={{
                        color: "#3F4144",
                        background: '#FFF',
                        padding: '12px 16px',
                    }}
                >
                    {`Når: ${node.data.formattedX}`}
                    <br />
                    {`Tid: ${node.data.formattedY} sekunder`}
                </div>
            )}
            axisLeft={{
                orient: 'left',
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: 'sekunder',
                legendPosition: 'middle',
                legendOffset: -46
            }}
            enableGridX={false}
            legends={[]}
            xScale={{
                type: 'time',
                format: 'native',
                useUTC: false,
                precision: 'minute',
            }}
            xFormat="time:%Y-%m-%d"
            axisBottom={{
                format: '%b %d',
                tickValues: 'every 7 days',
                legend: 'Tid mellom Startkommando og Aggrspenning>600V',
                legendPosition: 'middle',
                legendOffset: 46
            }}
            colors={["#4BB2E7", "green"]}
            theme={{
                tooltip: {
                    container: {
                        color: "#3F4144"
                    }
                },
                // @ts-ignore
                textColor: '#BDBDBD',
                grid: {
                    line: {
                        stroke: "#3F4144"
                    }
                },
                axis: {
                    ticks: {
                        line: {
                            stroke: '#ECECEC',
                            strokeWidth: 1
                        },
                        text: {
                            fontSize: 11
                        }
                    },
                }
            }}
        />
    );

    const MyResponsiveLine = () => (
        // @ts-ignore
        <ResponsiveLine
            data={[
                { id: 'actual', data: actualData, },
                { id: 'prediction', data: predictionData },
                // { id: 'production', data: productionData },
                // { id: 'cosphi', data: cosPhiData },
                // { id: 'indoorTemp', data: tempIndoorData },
                // { id: 'Ledeapparat', data: ledeapparatData },
                // { id: 'Løpehjul', data: lopehjulData },
            ]}
            enableGridX={false}
            enableGridY={true}
            margin={{ top: 24, right: 24, bottom: 68, left: 40 }}
            xScale={{
                type: 'time',
                format: 'native',
                useUTC: false,
                precision: 'minute',
            }}
            xFormat="time:%Y-%m-%d"
            yScale={{ type: 'linear', min: Math.min(...predictionData.map((item) => item.y)) - 6, max: Math.max(...actualData.map((item) => item.y)) + 6, stacked: false, reverse: false }}
            axisTop={null}
            axisRight={null}
            enableArea={false}
            areaBaselineValue={Math.min(...actualData.map((item) => item.y))}
            axisBottom={{
                format: '%d/%m',
                tickValues: 'every 24 hours',
                legendPosition: "middle",
                legend: 'Faktisk temperatur i styrelager mot predikert temperatur',
                legendOffset: 44,
            }}
            axisLeft={{
                orient: 'left',
                tickSize: 5,
                tickValues: 8,
                tickPadding: 5,
                tickRotation: 0,
                legend: '',
                legendOffset: -50,
                legendPosition: 'middle'
            }}
            enablePoints={false}
            colors={["#4BB2E7", "red", "blue", "orange", "green", "teal", "pink"]}
            pointSize={10}
            pointColor={{ theme: 'background' }}
            pointBorderWidth={2}
            pointBorderColor={{ from: 'serieColor' }}
            pointLabel="y"
            pointLabelYOffset={-12}
            useMesh={true}
            theme={{
                // @ts-ignore
                textColor: '#BDBDBD',
                axis: {
                    ticks: {
                        line: {
                            stroke: '#FFF',
                            strokeWidth: 1
                        },
                        text: {
                            fontSize: 11
                        }
                    },
                },
                grid: {
                    line: {
                        stroke: "#3F4144"
                    }
                },
                legends: {
                    text: {
                        color: "#BDBDBD",
                    }
                }
            }}

            layers={[
                'grid',
                'markers',
                'areas',
                'lines',
                'slices',
                'axes',
                'points',
                'legends',
            ]}
            legends={[
                {
                    anchor: 'top',
                    direction: 'row',
                    justify: false,
                    translateX: 0,
                    translateY: -12,
                    itemsSpacing: 12,
                    itemDirection: 'left-to-right',
                    itemWidth: 65,
                    itemHeight: 20,
                    itemOpacity: 0.75,
                    itemTextColor: "#BDBDBD",
                    symbolSize: 12,
                    symbolShape: 'circle',
                    symbolBorderColor: 'rgba(0, 0, 0, .5)',
                    effects: [
                        {
                            on: 'hover',
                            style: {
                                itemBackground: 'rgba(0, 0, 0, .03)',
                                itemOpacity: 1
                            }
                        }
                    ]
                }
            ]}
        />
    );

    const RusingLine = () => (
        // @ts-ignore
        <ResponsiveLine
            data={[
                { id: 'Ledeapparat', data: leadData, },
                { id: 'Turtall', data: rpmData, },
                { id: 'Trykk', data: pressureData, },
            ]}
            enableGridX={false}
            enableGridY={true}
            margin={{ top: 24, right: 24, bottom: 68, left: 40 }}
            xScale={{
                type: 'time',
                format: 'native',
                useUTC: false,
                precision: 'minute',
            }}
            xFormat="time:%Y-%m-%d"
            yScale={{ type: 'linear', min:0, max: 120, stacked: false, reverse: false }}
            axisTop={null}
            axisRight={null}
            enableArea={false}
            // areaBaselineValue={Math.min(...actualData.map((item) => item.y))}
            axisBottom={{
                format: '%d/%m',
                tickValues: 'every 24 hours',
                legendPosition: "middle",
                legend: 'Turtall (%), trykk i innløpsrør og ledeapparat posisjon',
                legendOffset: 44,
            }}
            axisLeft={{
                orient: 'left',
                tickSize: 5,
                tickValues: 8,
                tickPadding: 5,
                tickRotation: 0,
                legend: '',
                legendOffset: -50,
                legendPosition: 'middle'
            }}
            enablePoints={false}
            colors={["#4BB2E7", "red", "orange", "orange", "green", "teal", "pink"]}
            pointSize={10}
            pointColor={{ theme: 'background' }}
            pointBorderWidth={2}
            pointBorderColor={{ from: 'serieColor' }}
            pointLabel="y"
            pointLabelYOffset={-12}
            useMesh={true}
            theme={{
                // @ts-ignore
                textColor: '#BDBDBD',
                axis: {
                    ticks: {
                        line: {
                            stroke: '#FFF',
                            strokeWidth: 1
                        },
                        text: {
                            fontSize: 11
                        }
                    },
                },
                grid: {
                    line: {
                        stroke: "#3F4144"
                    }
                },
                legends: {
                    text: {
                        color: "#BDBDBD",
                    }
                }
            }}

            layers={[
                'grid',
                'markers',
                'areas',
                'lines',
                'slices',
                'axes',
                'points',
                'legends',
            ]}
            legends={[
                {
                    anchor: 'top',
                    direction: 'row',
                    justify: false,
                    translateX: 0,
                    translateY: -12,
                    itemsSpacing: 12,
                    itemDirection: 'left-to-right',
                    itemWidth: 65,
                    itemHeight: 20,
                    itemOpacity: 0.75,
                    itemTextColor: "#BDBDBD",
                    symbolSize: 12,
                    symbolShape: 'circle',
                    symbolBorderColor: 'rgba(0, 0, 0, .5)',
                    effects: [
                        {
                            on: 'hover',
                            style: {
                                itemBackground: 'rgba(0, 0, 0, .03)',
                                itemOpacity: 1
                            }
                        }
                    ]
                }
            ]}
        />
    );

    return (
        <>
            <Row>
                <Col xs={24} md={24} lg={16}>
                    <Row>
                        <Col xs={24} style={{ paddingLeft: 24, paddingTop: 24 }}>
                            <h1 style={{ color: "#FFF", marginBottom: 0 }}><PieChartOutlined /> Analytics</h1>
                            <div style={{ color: "#FFF", marginTop: 0, opacity: 0.7, marginBottom: 0 }}>Machine learning, AI and statistics used to improve reliability and predict maintenance</div>
                        </Col>
                        <Col xs={24}>
                            <div style={{ margin: "0 auto", color: "#FFF", padding: 24, paddingTop: 0 }}>
                                <BigDivider name="OPPSTART" duration="Tidenes morgen" />
                                <div style={{ height: 500, width: "100%", backgroundColor: "#1E2125" }}>
                                    <MyResponsiveScatterPlot />
                                </div>
                            </div>
                        </Col>
                        <Col xs={24}>
                            <div style={{ margin: "0 auto", color: "#FFF", padding: 24, paddingTop: 0 }}>
                                <BigDivider name="Styrelager" duration="Tidenes morgen" />
                                <div style={{ height: 500, width: "100%", backgroundColor: "#1E2125" }}>
                                    <MyResponsiveLine />
                                </div>
                            </div>
                        </Col>
                        <Col xs={24}>
                            <div style={{ margin: "0 auto", color: "#FFF", padding: 24, paddingTop: 0 }}>
                                <BigDivider name="Turtall" duration="Tidenes morgen" />
                                <div style={{ height: 500, width: "100%", backgroundColor: "#1E2125" }}>
                                    <RusingLine />
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Col>
                <Col xs={24} md={24} lg={8} style={{ backgroundColor: "#FFF" }}>
                    <Card title={"Hendelser"} bordered={false} extra={<Select defaultValue="lucy" style={{ width: 120, height: "auto" }} disabled>
                        <Option value="lucy">I år</Option>
                    </Select>} bodyStyle={{ paddingTop: 0, paddingBottom: 12, paddingLeft: 16, paddingRight: 16 }} style={{ width: "100%" }} headStyle={{ height: 40, marginBottom: 6, borderBottomWidth: 0, paddingLeft: 16, paddingRight: 16 }}>
                        <EventsCalendarWidget size={20} />
                    </Card>
                </Col>
            </Row>
        </>
    );
}

export default HydropowerPlantAnalytics;
