import React, { useEffect, useState } from "react";
import { Timestamp } from "../../service/src";
import { Col, Row, Select, Button } from "antd";
import { PlusOutlined, ToolOutlined } from "@ant-design/icons";

const { Option } = Select;

interface IBigDivider {
    name: string;
    duration: string
}

function BigDivider(props: IBigDivider) {

    return (
        <Col xs={24} md={24} xl={24} style={{ padding: 0 }}>
            <div style={{backgroundColor: "#1E2125", borderBottomColor: "#16191D", borderBottomWidth: 1, borderBottomStyle: "solid", borderTopRightRadius: 6, borderTopLeftRadius: 6  }}>
                <div style={{ height: 0, width: "100%", marginTop: 24 }} />
                <Row>
                    <Col xs={10} style={{ padding: 12 }}>
                        <div style={{ maxWidth: 150, height: 32, backgroundColor: "#1E2125", paddingTop: 4, paddingBottom: 4, }}>
                            <span style={{ opacity: 0.9, fontSize: 12 }}>{props.name}</span>
                        </div>
                    </Col>
                    <Col xs={14} style={{ padding: 12 }}>
                        <div style={{ width: "auto", float: "right", backgroundColor: "#1E2125", padding: 4, paddingRight: 0, paddingLeft: 8,  }}>
                            <Select size={"small"} defaultValue="realtime" style={{ width: 110, opacity: 0.5 }} disabled>
                                <Option value="realtime">{props.duration}</Option>
                            </Select>
                            <Button style={{ float: "right", marginLeft: 12 }} size={"small"} shape={"circle"} ghost={true} icon={<ToolOutlined />}></Button>
                        </div>
                    </Col>
                </Row>
            </div>
        </Col>
    );
}

export default BigDivider;
