
import React, { useEffect, useState } from "react";
import AnimatedNumber from 'react-animated-number';
import { Sparklines, SparklinesLine } from 'react-sparklines';
import { Sensor } from "../../service";
import { KinetikRealtimePlantDataMessage } from "../../service";
import SparklinesSpot from "../General/SparklinesSpot";

import { Tag } from "antd";
import { useTracked } from '../../state';

// @ts-ignore
import styles from "./styles.module.scss";

export interface ISensorMarker {
    sensor?: Sensor;
    x?: number;
    y?: number;
}

function SensorMarker(props: ISensorMarker) {

    const [latestRealtimeData, setLatestRealtimeData] = useState<KinetikRealtimePlantDataMessage>({ kinetikData: {} });
    const [state, dispatch] = useTracked();
    const [realtimeChartData, setRealtimeChartData] = useState([]);
    const [showData, setShowData] = useState(false);
    const [value, setValue] = useState();

    const latest = state.hydropowerrealtimeplantlatest[state.hydropowerrealtimeplantlatest.findIndex((item) => item.id === state.hydropowerplant.id)];
    const sensor = latest ? latest.sensors.find(((sensorItem) => sensorItem.name === props.sensor.name)) : { value: 0 };

    useEffect(() => {
        if (sensor) {
            setShowData(true);
            setValue(sensor.value);
            if (realtimeChartData.length > 20) { realtimeChartData.shift(); }
            realtimeChartData.push(sensor.value);
            setRealtimeChartData(realtimeChartData);
        }
    }, [sensor.value]);

    /*useEffect(() => {
        const latest = state.hydropowerrealtimeplantdata[state.hydropowerrealtimeplantdata.length - 1];

        if (latest !== undefined) {
            if (latest.id === props.hydropowerPlant.id) {
                setShowData(true);
                setProductionDescription("KW");
                setLatestRealtimeData(latest);
                const realtimeChartData = state.hydropowerrealtimeplantdata.map((data) => { if (data.id === props.hydropowerPlant.id) { return (data.kinetikData.production); } });
                setRealtimeChartData(realtimeChartData);
            }
        }
    }, [state.hydropowerrealtimeplantdata]);*/

    const markerSize = 15;

    return (
        <div style={{ width: 100, paddingTop: 12, backgroundColor: "#FFF", borderRadius: 6, marginBottom: -15 }} className={styles.pointer}>
            <div style={{ width: "auto" }}>
                <div style={{ fontWeight: 400, width: "100%", color: "#16191D", fontSize: 14, lineHeight: 1, textAlign: "center" }}>
                    {props.sensor.name}
                </div>
                <div style={{ marginTop: 12, padding: 6 }}>
                    {!showData ?
                        <Tag style={{ width: "100%", textAlign: "center" }} color="#E74B5E">Offline</Tag> :
                        <div style={{ textAlign: "center" }}><AnimatedNumber component="span" stepPrecision={1} value={value}
                            style={{
                                transition: '0.8s ease-out',
                                fontSize: 18,
                                fontWeight: 900,
                                transitionProperty:
                                    'background-color, color, opacity'
                            }}
                            formatValue={(n) => n.toFixed(1)}
                            duration={800}
                        /><span style={{ fontWeight: 400, marginLeft: 6 }}>{props.sensor.props.find((a) => a.name === "Unit").value}</span></div>
                    }


                </div>
                <div style={{ height: 30, paddingRight: 12, paddingLeft: 12, paddingTop: 0, paddingBottom: 12 }}>
                    <Sparklines data={realtimeChartData} limit={20} margin={12}>
                        <SparklinesLine style={{ fill: "#16191D", strokeWidth: 4 }} color="#4BB2E7" />
                        <SparklinesSpot style={{ stroke: "#4BB2E7", strokeWidth: 6, fill: "white" }} size={10} />
                    </Sparklines>
                </div>
            </div>
        </div>
    );
}

export default SensorMarker;
