
import React, { useEffect, useState } from "react";

// @ts-ignore
import { Defs } from '@nivo/core';

import { ResponsiveLine } from '@nivo/line';
import { area, curveMonotoneX } from 'd3-shape';
import { KinetikRealtimeCustomerMessage, Sensor } from "../../service";

import { useTracked } from "../../state";

import moment from "moment";

// @ts-ignore
// import styles from "./styles.module.scss";

export enum ILineWidgetType {
    realtimePlantData = 1,
    realtimeCustomerData = 2,
    realtimePlant = 3,
}

export interface ILineWidget {
    sensor: Sensor;
    type: ILineWidgetType;
}
function LineWidget(props: ILineWidget) {

    const [state, dispatch] = useTracked();
    const [latestRealtimeData, setLatestRealtimeData] = useState<KinetikRealtimeCustomerMessage>({ kinetikData: {} });
    const [realtimeChartData, setRealtimeChartData] = useState([]);

    useEffect(() => {
        if (props.type === ILineWidgetType.realtimeCustomerData) {
            dispatch({ type: 'GET_CUSTOMERDATA', request: { end: moment().unix().valueOf(), start: moment().add(-48, 'hours').unix().valueOf(), groupBy: "10m" } });
        }
    }, [props]);

    useEffect(() => {
        if (props.type === ILineWidgetType.realtimeCustomerData) {
            const realtimeChartData = state.customerdata.map((data) => ({ x: moment(data.timestamp).toDate(), y: data.production ? data.production : null }));
            setRealtimeChartData(realtimeChartData);
        }
    }, [state.customerdata]);

    useEffect(() => {
        if (props.sensor) {
            if (props.type === ILineWidgetType.realtimePlant) {
                dispatch({ type: 'GET_HYDROPOWERPLANTSENSORDATA', request: { sensor: props.sensor.name, end: moment().unix().valueOf(), start: moment().add(-48, 'hours').unix().valueOf(), groupBy: "10m" } });
            }
        }
    }, [props]);

    useEffect(() => {
        if (props.type === ILineWidgetType.realtimePlant) {
            const realtimeChartData = state.hydropowerplantsensordata.map((data) => ({ x: moment(data.timestamp).toDate(), y: data.value ? data.value : null }));
            setRealtimeChartData(realtimeChartData);
        }
    }, [state.hydropowerplantsensordata]);

    const AreaLayerHasData = ({ series, xScale, yScale, innerHeight }) => {
        const areaGenerator = area()
            .x(d => xScale(d.data.x))
            .y0(d => Math.min(innerHeight, yScale(d.data.y - d.data.y)))
            .y1(d => yScale(d.data.y ? d.data.y : 0))
            .curve(curveMonotoneX);

        return (
            <>
                <Defs
                    defs={[
                        {
                            id: 'pattern',
                            type: 'patternLines',
                            background: 'transparent',
                            color: '#3daff7',
                            lineWidth: 1,
                            spacing: 6,
                            rotation: -45,
                        },
                    ]}
                />
                <path
                    d={areaGenerator(series[0].data)}
                    fill="url(#pattern)"
                    fillOpacity={0.2}
                    stroke="#3daff7"
                    strokeWidth={0}
                />
            </>
        );
    };

    const AreaLayerMissingData = ({ series, xScale, yScale, innerHeight }) => {


        const areaGenerator = area()
            .x(d => xScale(d.data.x))
            .y0(d => Math.min(innerHeight, yScale(d.data.y - d.data.y)))
            .y1(d => yScale(d.data.y ? 0 : 1000))
            .curve(curveMonotoneX);

        return (
            <>
                <Defs
                    defs={[
                        {
                            id: 'pattern1',
                            type: 'patternLines',
                            background: 'transparent',
                            color: '#AFAFAF',
                            lineWidth: 1,
                            spacing: 6,
                            rotation: -45,
                        },
                    ]}
                />
                <path
                    d={areaGenerator(series[0].data)}
                    fill="url(#pattern1)"
                    fillOpacity={0.3}
                    stroke="#AFAFAF"
                    strokeWidth={0}
                />
            </>
        );
    };

    const styleById = {
        default: {
            strokeDasharray: '2, 4',
            strokeWidth: 2,
            opacity: 0.4
        },
    }

    const DashedLine = ({ series, lineGenerator, xScale, yScale }) => {
        return series.map(({ id, data, color }) => (
            <path
                key={id}
                d={lineGenerator(
                    data.map(d => {
                        return {
                            x: xScale(d.data.x),
                            y: yScale(d.data.y)
                        }
                    }))}
                fill="none"
                stroke={color}
                style={styleById[id] || styleById.default}
            />
        ));
    };

    const MyResponsiveLine = (
        // @ts-ignore
        <ResponsiveLine
            data={[
                { id: 'A', data: realtimeChartData }
            ]}
            enableGridX={false}
            enableGridY={false}
            margin={{ top: 6, right: 12, bottom: 24, left: 40 }}
            xScale={{
                type: 'time',
                format: 'native',
                useUTC: false,
                precision: 'minute',
            }}
            xFormat="time:%Y-%m-%d"
            yScale={{ type: 'linear', min: Math.min(...realtimeChartData.map((item) => item.y)), max: 'auto', stacked: true, reverse: false }}
            axisTop={null}
            axisRight={null}
            enableArea={true}
            areaBaselineValue={Math.min(...realtimeChartData.map((item) => item.y))}
            axisBottom={{
                format: '%H',
                tickValues: 'every 4 hours',
                legend: '',
                legendOffset: -12,
            }}
            axisLeft={{
                orient: 'left',
                tickSize: 5,
                tickValues: 4,
                tickPadding: 5,
                tickRotation: 0,
                legend: '',
                legendOffset: -50,
                legendPosition: 'middle'
            }}
            enablePoints={false}
            colors="#4BB2E7"
            pointSize={10}
            pointColor={{ theme: 'background' }}
            pointBorderWidth={2}
            pointBorderColor={{ from: 'serieColor' }}
            pointLabel="y"
            pointLabelYOffset={-12}
            useMesh={true}
            theme={{
                // @ts-ignore
                textColor: '#FFF',
                axis: {
                    ticks: {
                        line: {
                            stroke: '#FFF',
                            strokeWidth: 1
                        },
                        text: {
                            fontSize: 11
                        }
                    },
                }
            }}

            layers={[
                'grid',
                'markers',
                'areas',
                AreaLayerMissingData,
                'lines',
                'slices',
                'axes',
                'points',
                'legends',
            ]}
            legends={[]}
        />);

    return (
        <div style={{ height: "auto"  }}>
            <div style={{ marginTop: 10, height: 200, }}>
                {MyResponsiveLine}
            </div>
        </div>
    );
}

export default LineWidget;
