
import React, { useEffect, useState } from "react";
import { Customer } from "../../service";
import { ResponsiveCalendar } from '@nivo/calendar';
import { Table, Tag } from "antd";
import moment from "moment";

import { useTracked } from "../../state";

import { CheckOutlined, ExclamationOutlined } from "@ant-design/icons";

// @ts-ignore
import styles from "./eventscalendarwidget.module.scss";

export interface IEventsCalendarWidget {
    customer?: Customer;
    size?: number;
}
function EventsCalendarWidget(props: IEventsCalendarWidget) {

    const [state, dispatch] = useTracked();
    const [chartData, setChartData] = useState([]);

    useEffect(() => {
        dispatch({ type: 'GET_CUSTOMEREVENTSCOUNTDAY', request: { end: moment().unix().valueOf(), start: moment().startOf('year').unix().valueOf(), groupBy: "1d" } });
        dispatch({ type: 'GET_CUSTOMEREVENTS', request: { end: moment().unix().valueOf(), start: moment().startOf('year').unix().valueOf(), groupBy: "1d" } });
    }, []);

    useEffect(() => {
        const _chartData = state.customereventscountday.filter((data) => data.count).map((data) => {
            if (data.count) {
                return { day: moment(data.timestamp).format("YYYY-MM-DD"), value: data.count };
            }
        });
        setChartData(_chartData);
    }, [state.customereventscountday]);

    const MyResponsiveCalendar = () => (
        <ResponsiveCalendar
            data={chartData}
            from={moment().startOf('year').format("YYYY-MM-DD")}
            to={moment().endOf('year').format("YYYY-MM-DD")}
            emptyColor="#eeeeee"
            colors={['#C8ECFF', '#4BB2E7', '#1C6286', '#E77A4B']}
            margin={{ top: 12, right: 0, bottom: 12, left: 0 }}
            yearSpacing={40}
            monthBorderColor="#ffffff"
            dayBorderWidth={2}
            monthBorderWidth={5}
            minValue={0}
            dayBorderColor="#ffffff"
            legends={[
                {
                    translateX: 0,
                    anchor: 'bottom-right',
                    direction: 'row',
                    translateY: 0,
                    itemCount: 3,
                    itemWidth: 42,
                    itemHeight: 36,
                    itemsSpacing: 14,
                    itemDirection: 'right-to-left'
                }
            ]}
            theme={{
                // @ts-ignore
                textColor: '#BDBDBD',
                axis: {
                    ticks: {
                        line: {
                            stroke: '#FFF',
                            strokeWidth: 1
                        },
                        text: {
                            fontSize: 11
                        }
                    },
                }
            }}
        />
    );

    const columns = [
        {
            title: 'State',
            dataIndex: 'state',
            key: 'state',
            //render: (item, i) => <Tag className={i.active ? styles.active : styles.deactive} >{item}</Tag>
        },
        {
            title: 'Active',
            dataIndex: 'active',
            key: 'active',
            width: "40px",
            align: "center",
            render: (item) => item ? <Tag>Y</Tag> : <Tag color={"grey"}>N</Tag>
        },
        {
            title: 'Timestamp',
            dataIndex: 'timestamp',
            key: 'timestamp',
            width: "150px",
            align: "right",
            render: (item) => moment(item).format("YYYY-MM-DD HH:mm:ss")
        },
    ];

    return (
        <div>
            <div style={{ minHeight: 180, height: 70 }}>{MyResponsiveCalendar()}</div>
            <Table size={"small"} tableLayout="auto" rowKey={'timestamp'} dataSource={state.customerevents.sort((a, b) => moment(b.timestamp).unix() - moment(a.timestamp).unix())} columns={columns} pagination={{ showLessItems: false, pageSize: props.size ? props.size : 15, showSizeChanger: false, position: ['bottomCenter'] }} />
        </div>
    );
}

export default EventsCalendarWidget;
