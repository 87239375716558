
import { Tag } from "antd";
import React, { useEffect, useState } from "react";
import { State } from "../../service";
import { useTracked } from "../../state";

// @ts-ignore
import styles from "./statewidget.module.scss";

export interface IStateWidget {
    state: State;
}

function StateWidget(props: IStateWidget) {

    const [state, dispatch] = useTracked();
    const [stateTag, setStateTag] = useState<State>({ active: false });

    const _latest = state.hydropowerrealtimeplantlatest[state.hydropowerrealtimeplantlatest.findIndex((item) => item.id === state.hydropowerplant.id)];
    const _stateTag = _latest !== undefined ? _latest.states.find(((stateItem) => stateItem.name === props.state.name)) : { active: false };

    useEffect(() => {
        if (_stateTag !== undefined) {
            setStateTag(_stateTag);
        }
    }, [_stateTag]);

    return (
        <>
            <Tag key={props.state.name} className={stateTag.active ? styles.active : styles.deactive}>{props.state.name}</Tag>
        </>
    );
}

export default StateWidget;
